@import 'src/styles/variables';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 18px;
  margin: 6px 4px;
  font-family: $cerebri;
  min-width: 75px;
  min-height: 42px;
  line-height: 38px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  overflow: hidden;
  transition: background-color 0.1s $cubic;
  will-change: background;
  word-wrap: break-word;

  @include for-size(small-phone-only) {
    line-height: normal;
    white-space: normal;
  }

  & ~ & {
    margin-left: 10px;
  }

  // @include ie-only {
  //   // apply these styles on ie only
  //   height: 42px; // fix on flex child not centering vertically when no height is provided
  // }
}

.blue {
  background-color: $blue-100;
  color: $white;

  &:hover {
    background-color: $blue-alt;
  }
}

.darkblue {
  background-color: $darkblue;
  color: $white;

  &:hover {
    background-color: $darkblue-alt;
    color: $white;
  }

  &:active {
    background-color: $darkblue;
  }
}

.white {
  background-color: $blue-5;
  color: $darkblue;

  &:hover {
    background-color: $white;
    color: $darkblue;
    box-shadow: $card-shadow;
  }
}

.blueOutline {
  color: $blue-100;
  border-color: $blue-100;

  &:hover {
    border-color: $darkblue;
    color: $darkblue;
  }
}

.whiteBlueOutline {
  color: $darkblue;
  border-color: $white;
  background-color: $white;
  border-color: $darkblue;
  word-break: break-word;
  white-space: normal;

  &:hover {
    background-color: $gray-5;
    color: $darkblue;
  }
}

.darkBlueOutline {
  color: $darkblue;
  border: 2px solid $darkblue;

  &:hover {
    border-color: $blue-100;
    color: $blue-100;
    background: $white;
  }
}

.fullwidth {
  width: 100%;
  margin: 6px 0px;
}

.large {
  height: 56px;
}

.fullwidthLarge {
  width: 100%;
  margin: 6px 0px;
  height: 56px;
}
