@import 'src/styles/variables';

.heroMainTop {
  margin: 0px auto;
  text-align: center;

  @include for-size(tablet-landscape-down) {
    margin: 0 15px;
  }

  @include for-size(phone-only) {
    margin: 0px 10px;
    text-align: left;
  }
}

.heroTitle {
  margin: 0;
  color: $white;
  letter-spacing: -1px;
  font-size: 48px;
  line-height: 60px;
  font-weight: 500;
  margin: auto;
  margin-bottom: 20px;
  // max-width: 750px;

  @include for-size(tablet-landscape-down) {
    font-size: 40px;
    line-height: 48px;
  }

  @include for-size(phone-only) {
    font-size: 36px;
    line-height: 40px;
  }
}

.heroMainDes {
  max-width: 760px;
  margin: 0px auto;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 30px;

  @include for-size(phone-only) {
    font-size: 15px;
    line-height: 1.5;
    text-align: left;
  }
}

.container {
  max-width: $tablet-landscape-upper-boundary;
  width: 100%;
  padding: 200px 0 30px;
  margin: 0 auto;
  display: flex;
  color: $white;
  box-sizing: border-box;
  flex-wrap: wrap;
  z-index: $z-base;

  @include for-size(desktop-up) {
    padding: 150px 0 20px;
  }

  @include for-size(phone-only) {
    padding: 100px 10px 0px;
  }
}

.bottom {
  max-width: $tablet-landscape-upper-boundary;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: $z-base;

  p {
    margin-top: 6px;
  }
}

.boot {
  width: 100%;
  height: auto;
  bottom: -5px;
  color: $blue-5;
  z-index: 1;
}

.cMainHeroCta {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .btnCta {
    @include for-size(phone-only) {
      margin: 5px 0;
    }
  }
}

.gridReverse {
  @include for-size(phone-only) {
    flex-direction: column-reverse;
  }
}
