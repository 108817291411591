@import 'src/styles/variables';

.list {
    list-style-type: none;
    line-height: 32px;
}

.anchor {
    text-decoration: none;
	font-size: 16px;
	font-weight: 300;
}

.darkblue {
    color: $darkblue;
}

.darkgray {
    color: $gray-80;
}

.gray {
    color: $gray-60;
}

.white {
    color: $white;
}

.blackBold {
    color: $black;
    font-weight: 600;
}