@import 'src/styles/variables';

.container {
  width: 100%;
}

.headline {
  margin: 0 10px 10px 0;
  color: $white;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.5px;

  @include for-size(medium-phone-only) {
    font-size: 24px;
  }
}

.body {
  line-height: 1.5;
  margin: 0;
  color: $white;
  font-size: 18px;

  @include for-size(tablet-landscape-down) {
    margin-bottom: 15px;
  }

  @include for-size(phone-only) {
    font-size: 16px;
  }
}

.input {
  height: 42px;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
  background: $white;
  border: 0;
  border-radius: 3px;
  font-size: 16px;
  color: $black;
  font-weight: 300;
}

.formContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 80px;

  @include for-size(tablet-landscape-down) {
    margin-left: 0;
    // margin-top: 20px;
    width: 100%;
  }
}

.formContainerFull {
  margin-left: 0;
  width: 100%;
  .form {
    margin-top: 10px;
  }
}

.modal {
  padding: 30px 40px;
  max-width: 1200px;
  width: 100%;
  background: $darkblue;
  box-shadow: 0 5px 40px rgba(4, 13, 71, 0.8);
  border-radius: 5px;
  position: relative;

  box-sizing: border-box;
  //margin: auto;
  margin: 20px;

  @include for-size(medium-phone-only) {
    padding: 20px;
  }
}

.modalContent {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  //margin: 10px auto;

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
  }

  &.modalContentFull {
    flex-direction: column;
  }
}

.introWrapper {
  // flex-basis: 50%;

  @include for-size(small-phone-only) {
    svg {
      display: none !important;
    }
  }
}

.formWrapper {
  // flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-left: 10px;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 6px;
  height: 6px;
  border: 2px solid $white;
  border-radius: 50%;
  float: left;
  margin: 0 2.5px;
  transform: scale(0);

  animation: fx 1000ms ease infinite 0ms;

  &:nth-child(2) {
    animation: fx 1000ms ease infinite 300ms;
  }

  &:nth-child(3) {
    animation: fx 1000ms ease infinite 600ms;
  }
}

@keyframes fx {
  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.form {
  display: flex;
  align-items: center;

  @include for-size(medium-phone-only) {
    flex-direction: column;
  }

  .button {
    // the old popup__button
    border: none;
    margin: 0 0 0 10px;
    width: 122px;
    height: 56px;
    background-color: $blue-100;
    color: $white;
    font-weight: 500;
    font-size: 16px;

    &:hover {
      background-color: $blue-alt;
    }
  }

  .loader {
    width: 122px;
    margin-left: 10px;
    margin: 10px;
  }
}

.fullContainer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 200;
  display: flex;
  flex-wrap: wrap;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.bottomContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $z-important;
  transition: all 0.4s $cubic;
  display: flex;
  justify-content: center;
  align-items: center;

  .matLogo {
    display: none;
  }

  @include for-size(phone-only) {
    background-color: rgba(0, 0, 0, 0.4);
    flex-wrap: wrap;
    top: 0;
    z-index: 200;

    .matLogo {
      display: block;
    }
  }
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 7px;
  height: 34px;
  width: 34px;
  color: $white;
  font-size: 50px;
  font-weight: 600;
  transition: all 0.1s ease-in;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background: $white;
    cursor: pointer;
    opacity: 1;

    &:before,
    &:after {
      background: $darkblue;
    }
  }

  &:before,
  &:after {
    color: $white;
    position: absolute;
    background-color: $white;
    width: 26px;
    content: '';
    transition: transform 0.3s;
    height: 3px;
    left: 4px;
    top: 16px;
  }
  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.matLogo {
  margin-bottom: 20px;
}

.popButton {
  // the old pop__button
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 18px;
  min-width: 75px;
  min-height: 38px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  overflow: hidden;
  line-height: 38px;
  transition: $bg-cubic;

  @include for-size(tablet-landscape-up) {
    margin: 0px 4px 0px 10px;
  }

  @include for-size(medium-phone-only) {
    margin: 10px 0;
    width: 100%;
  }
}

.fullModal {
  max-width: 500px;
}
