@import 'src/styles/variables';

.portfolioList {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 390px;
}

.portfolioItem {
  position: relative;
  min-height: 337px;
  width: 250px;
  min-width: 250px;

  text-align: center; // fix on ie

  background-color: $white;
  padding: 20px 15px;
  box-sizing: border-box;
  margin: 30px 10px;
  box-shadow: $card-shadow;
  border-radius: 6px;
  text-decoration: none;
  transition: transform 0.3s $cubic;

  &:before {
    pointer-events: none;
    content: '';
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 6px;
    box-shadow: $card-shadow-hover;
    mix-blend-mode: multiply;
    transition: all 0.3s $cubic;
    z-index: $z-background;
  }

  &:hover {
    transform: translateY(-5px);
  }

  &:hover:before {
    opacity: 1;
  }
}

.scroll {
  display: flex;
  overflow-x: scroll;
  margin: 0 auto;
  margin-right: 20px;
  -webkit-overflow-scrolling: touch;

  @include for-size(tablet-landscape-down, 68) {
    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
}

.removeScroll {
  position: absolute;
  height: 10px;
  width: 100%;
  bottom: 0px;
}

.largePortfolioItem {
  width: 280px;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: auto;
  padding: 20px;

  text-decoration: none;
}

.largeImage {
  margin: 0 auto 15px;
  border-radius: 50%;
  height: 175px;
  max-width: 175px;
  object-fit: cover;
}

.largeName {
  color: $black;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 15px;
}

.largePrimarySkill {
  font-size: 16px;
  font-weight: 600;
  color: $dark-blue;
  margin-bottom: 15px;
  text-align: center;
}

.imageContainer {
  margin: 0 auto 15px;
  height: 120px;
  width: 120px;
  display: block;
}

.image {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.name {
  color: $dark-blue;
  background-color: $white;
  text-align: center;
  font-weight: 600;
  // font-family: $publicsans;

  font-size: 20px;
  width: 100%; // fix on ie overlapping text
  word-wrap: break-word; // fix on ie overlapping text
}

.primarySkill {
  font-size: 16px;
  font-weight: 600;
  background-color: $white;
  color: $black;
  margin: 5px 0;
  margin-bottom: 10px;
  text-align: center;

  width: 100%; // fix on ie overlapping text
  word-wrap: break-word; // fix on ie overlapping text
  //

  // font-size: 13px;
  // text-transform: uppercase;
  // letter-spacing: -.1px;
  // color: $dark-gray-shadow;
}

.skills {
  // margin-top: 20px;
  max-height: 62px;
}
