@import 'src/styles/variables';

.seo {
  background-image: url('/images/hero/main.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  :global(.no-webp) & {
    background-image: url('/images/hero/main.png');
  }
}

.profile {
  position: relative;
  padding: 10px 0;
  background-image: url('/images/hero/main.webp');
  background-color: $blue-100;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 150px;

  :global(.no-webp) & {
    background-image: url('/images/hero/main.png');
  }
}

.careers {
  height: 400px;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 100%, #000000 100%),
    linear-gradient(40deg, #7ce3c8 20%, #007eff 100%),
    linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 120%), url('/images/hero/office.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color, color, multiply;

  @include for-size(phone-only) {
    height: 300px;
  }

  @include for-size(tablet-portrait-up) {
    height: 300px;
  }

  @include for-size(desktop-up) {
    height: 400px;

    .container {
      padding: 200px 0 20px;
    }
  }
}

// .faq {
//   position: relative;
//   background-color: $blue-shadow;

//   @include for-size(desktop-up) {
//     .container {
//       padding: 200px 20px 150px;
//     }
//   }

//   @include for-size(desktop-down) {
//     min-height: 0;

//     .container {
//       padding: 150px 20px 100px;
//     }
//   }
// }

// .faqTop {
//   margin: 0 auto;
//   text-align: center;
// }

.creator {
  position: relative;
  height: 550px;
  margin-bottom: -100px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - 6vw));
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #1268db, #0d7fe2);
    opacity: 0.6;
    z-index: $z-behind;
  }

  @include for-size(phone-only) {
    height: 600px;
  }
}

.about {
  position: relative;
  // height: 400px;
  background-image: url('/images/hero/client.webp');
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // background-blend-mode: color, color, multiply;

  :global(.no-webp) & {
    background-image: url('/images/hero/client.jpg');
  }

  @include for-size(desktop-up) {
    // height: 416px;
    .container {
      padding: 150px 20px;
    }
  }

  @include for-size(desktop-down) {
    // height: 416px;
    min-height: 400px;
  }
}

.contact {
  // height: 900px;
  // background-image: url('/images/hero/client.webp');
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-blend-mode: color, color, multiply;
  background-color: $blue-shadow;

  // :global(.no-webp) & {
  //   background-image: url('/images/hero/client.jpg');
  // }
}

.referral {
  position: relative;

  background-color: $blue-shadow;

  // background-image: url('/images/hero/main.png');
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-blend-mode: color, color, multiply;
}

.register {
  position: relative;
  background-color: $dark-blue-shadow;

  min-height: 100vh;
  display: flex;
  align-items: center;
  //justify-content: center; // fix in ie, seems conflicting with the form margin auto

  @include for-size(phone-only) {
    background-color: $white;

    .waveContainer {
      display: none;
    }
  }
}

.companyRegister {
  position: relative;
  background-color: $blue-shadow;
  min-height: 100vh;
  display: flex;
  align-items: center;

  @include for-size(phone-only) {
    background-color: $white;

    .waveContainer {
      display: none;
    }
  }
}

.login {
  position: relative;
  background-color: $blue-shadow;
  min-height: 100vh;

  display: flex;
  align-items: center;
}

.talent {
  position: relative;
  background-image: url('/images/hero/talent.webp');
  background-color: $dark-blue;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  line-height: 0;
  z-index: 0;

  :global(.no-webp) & {
    background-image: url('/images/hero/talent.jpg');
  }
}

.client {
  position: relative;
  background-color: $blue-shadow;

  @include for-size(desktop-up) {
    .container {
      padding: 200px 20px 150px;
    }
  }

  @include for-size(desktop-down) {
    .container {
      padding: 150px 20px 100px;
    }
  }
}

.waveContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 0;
  left: 0;

  :global(.no-webp) & {
    display: none;
  }

  @include ie-only {
    display: none;
  }
}

.wave {
  pointer-events: none;
  opacity: 0.1;
  position: absolute;
  top: 45%;
  left: 50%;
  background: #0af;
  width: 10000px;
  height: 10000px;
  margin-left: -5000px;
  margin-top: -10000px;
  transform-origin: 50% 50%;
  border-radius: 45%;
  z-index: 0;
  will-change: transform;
  transform: rotate(20deg);
  animation: drift 80s infinite linear;
  animation-delay: 2s;
  opacity: 0.1;
}

.wave.three {
  top: calc(45% + 80px);
  border-radius: 40%;
  transform: rotate(40deg);
  animation: drift2 80s infinite linear;
  animation-delay: 2s;
}

.wave.two {
  top: calc(45% + 200px);
  border-radius: 45%;
  transform: rotate(60deg);
  animation: drift3 80s infinite linear;
  animation-delay: 2s;
}

.wave.waveStatic {
  animation: none;
}

.waveFive {
  pointer-events: none;

  &:before {
    display: block;
    content: '';
    background: #0af;
    height: 1000%;
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
  }
  position: absolute;
  bottom: 30%;
  left: 0;
  color: #0af;
  opacity: 0.05;
  width: 100%;

  @include ie-only {
    height: 337px;
  }
}

.waveFour {
  pointer-events: none;

  &:before {
    display: block;
    content: '';
    background: #0af;
    height: 200vh;
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    //top: 0;
  }
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  color: #0af;
  height: auto;
  opacity: 0.1;
  width: 100%;

  @include ie-only {
    height: 337px;
  }
}

@keyframes drift {
  from {
    transform: rotate(20deg);
  }

  from {
    transform: rotate(380deg);
  }
}

@keyframes drift2 {
  from {
    transform: rotate(40deg);
  }

  from {
    transform: rotate(400deg);
  }
}

@keyframes drift3 {
  from {
    transform: rotate(60deg);
  }

  from {
    transform: rotate(420deg);
  }
}

.container {
  max-width: $tablet-landscape-upper-boundary + 40px;
  width: 100%;
  padding: 150px 20px 20px;
  margin: auto;
  display: flex;
  color: $white;
  box-sizing: border-box;
  flex-wrap: wrap;

  @include for-size(desktop-up) {
    padding: 150px 0 20px;
  }
}

.containerContact {
  padding: 140px 20px 40px 20px;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $blue-shadow;
  padding: 0;
  z-index: 0;
}
.heroLeft {
  flex-basis: 50%;

  @include for-size(tablet-portrait-down) {
    flex-basis: 100%;
  }
}

.heroRight {
  flex-basis: 50%;
  position: relative;

  @include for-size(tablet-portrait-down) {
    flex-basis: 100%;
  }
}

.mainTop {
  margin: 0px auto;
  text-align: center;
}

.mainDes {
  max-width: 760px;
  margin: 0px auto;
  margin-bottom: 20px;
}

.enterprise {
  height: 800px;
  // background-image: url('/images/hero/enterprise.small.jpg');
  background-image: url('/images/hero/road.jpg');
  background-color: #041028;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  @include for-size(tablet-landscape-down) {
    height: auto;
  }
}

.bridge {
  height: 800px;
  background-image: url('/images/hero/bridge.webp');
  background-color: #041028;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  :global(.no-webp) & {
    background-image: url('/images/hero/bridge.jpg');
  }

  @include for-size(tablet-landscape-down) {
    height: auto;
  }
}

.engagement {
  background-image: url('/images/hero/road.jpg');
  padding: 20px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 120%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-size(tablet-landscape-down) {
    height: auto;
  }
}

.curveLightblue {
  width: 100%;
  height: auto;
  bottom: -2px;
  color: $white-shadow;
}

.bottom {
  max-width: $tablet-landscape-upper-boundary;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
  padding-bottom: 30px;

  p {
    margin-top: 6px;
  }
}

.bottomMain {
  padding-top: 15px;
  padding-bottom: 40px;
}

.center {
  max-width: 610px;
  width: 80%;
  margin: auto;
  text-align: center;

  @include for-size(phone-only) {
    width: calc(100% - 40px);
    padding: 0px 20px;
  }
}

.shadow {
  text-shadow: 0 0 100px rgba(0, 0, 0, 1), 0 0 200px rgba(0, 0, 0, 1);
}

.cta {
  padding: 30px 0 20px;
}

.error {
  background-image: url('/images/404.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 100%;
  // padding-top: 260px;
  margin-top: 40px;
  box-sizing: border-box;

  @include for-size(tablet-portrait-up) {
    height: 600px;
    // padding-top: 430px;
  }

  @include for-size(tablet-landscape-up) {
    height: 800px;
    // padding-top: 600px;
  }

  @include for-size(desktop-up) {
    .bottom {
      display: none;
    }
  }
}

.cMainHeroCta {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mainHeroCta {
  padding: 7px 24px;
  margin: 6px 10px;
  min-width: 200px;
}

//  NewHero

.blue {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $blue-shadow;
  padding: 0;
  z-index: 0;
}

.heroContainer {
  width: 100%;
  max-width: $content-width + 40px;
  margin: auto;
  z-index: 1;
  box-sizing: border-box;
}

.heroCenter {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include for-size(phone-only) {
    text-align: initial;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    align-items: inherit;
    justify-content: inherit;
  }
}

.heroTitle {
  margin: 0;
  color: $white;
  letter-spacing: -1px;
  font-size: 3.5rem;
  line-height: 1.125;
  font-weight: 500;
  max-width: 850px;
  margin-bottom: 25px;
  width: 100%;

  @include for-size(tablet-landscape-down) {
    font-size: 40px;
    line-height: 48px;
  }

  @include for-size(phone-only) {
    font-size: 34px;
    line-height: 40px;
  }
}

.heroDescription {
  max-width: 660px;
  color: $white;
  margin-bottom: 30px;
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 300;
  width: 100%;

  @include for-size(phone-only) {
    font-size: 18px;
    line-height: 28px;
  }
}

.heroCta {
  @include for-size(tablet-landscape-down) {
    width: 100%;
  }
}
.heroBottom {
  width: 100%;
  box-sizing: border-box;
  max-width: $content-width + 40px;
  margin: auto;
  padding: 90px 0 20px;

  @include for-size(tablet-landscape-down) {
    padding-top: 30px;
  }
}

.splitHero {
  display: flex;
}
