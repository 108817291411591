@import 'src/styles/variables';

.hasHero {
  margin-top: -60px;
}

.header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  max-width: $tablet-landscape-upper-boundary + 40px;

  height: 60px;
  margin: auto;
  padding: 10px 20px;

  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: sticky;
  top: 0;

  @include for-size(tablet-landscape-down) {
    // Mobile Menu
  }

  @include for-size(phone-only) {
    // Really Small
    max-width: 100%;
  }
}

.wrapper {
  z-index: $z-base;
}

.container {
  height: 60px;
  position: sticky;
  top: 0;
  width: 100%;

  // margin-top: 1px;
  z-index: $z-nav;
  .wrapper {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      transition: opacity 0.2s $cubic;
      will-change: opacity;
      pointer-events: none;
      opacity: 0;
      z-index: $z-underground;
    }
  }

  &.light {
    .wrapper:before {
      opacity: 1;
      transition: opacity 0.6s $cubic;
    }

    .mobileList {
      border-top: 1px solid $gray-10;
      background: $white;
    }
  }

  &.dark {
    .mobileList {
      border-top: 1px solid $darkblue-alt;
      background: $darkblue;
    }

    @include for-size(tablet-landscape-down) {
      .wrapper:before {
        background-color: $darkblue;
        opacity: 1;
        transition: opacity 0.6s $cubic;
      }
    }
  }
}

.sticky {
  .wrapper {
    &:before {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    }
  }
}

.logoSuffix {
  letter-spacing: 3px;
  font-size: 18px;
  font-weight: 700;
  margin-left: 16px;
  margin-top: 2px;
  font-family: $cerebri;

  @include for-size(medium-phone-only) {
    display: none;
  }
}

.logoLink {
  color: inherit;
  align-items: flex-start;
  display: flex;
  text-decoration: none;
  align-items: center;
  line-height: 0;
  justify-content: center;
  height: 100%;
}
.logo {
  margin: 6px 0 0;

  @include for-size(phone-only) {
    max-width: 200px;
  }
}

.navLink {
  color: $white;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 18px;
  border-radius: 3px;
  transition: background-color 0.1s $cubic, color 0.1s $cubic;

  @include for-size(tablet-landscape-down, 50px) {
    padding: 10px;
  }
}

.primaryButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 18px;
  font-family: $cerebri;
  min-width: 180px;
  min-height: 38px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  overflow: hidden;
  line-height: 38px;
  transition: $bg-cubic;
}

.dark {
  // dark background, light color
  color: $white;

  .primaryButton {
    background: $blue-5;
    color: $darkblue;

    &:hover {
      color: $darkblue;
      background: $white;
    }
  }

  .navLink {
    color: $white;

    &:hover {
      color: $yellow-100;
      // color: $darkblue;
      // background: $gray-5;
    }

    &.activeNavItem {
      color: $white;
      background: $darkblue;

      &:hover {
        color: $yellow-100;

        // color: $darkblue;
        // background: $gray-5;
      }
    }
  }
}

.light {
  // light background, dark color
  color: $darkblue;

  .primaryButton {
    background: $darkblue;
    color: $white;

    &:hover {
      background: $darkblue-alt;
    }
  }

  .navLink {
    color: $darkblue;

    &:hover {
      background: $gray-5;
    }

    &.activeNavItem {
      background: $gray-5;
    }
  }
}

.navItem {
  padding: 10px;
  white-space: nowrap;

  &:last-of-type {
    padding-right: 0;
  }
}

.leftBorder {
  &::before {
    // color: $darkblue;
    content: '|';
    margin-right: 20px;
  }

  & a:hover {
    // background: $gray-5;
    transition: color 0.1s $cubic;
    // color: $white;
  }
}

.navList {
  display: flex;
  align-items: center;

  @include for-size(tablet-landscape-down) {
    display: none;
  }
}

.mobileMenu {
  display: none;

  @include for-size(tablet-landscape-down) {
    display: block;
  }
  margin-left: auto;
  // position: fixed;

  // Overrides for the mobile version of the mneu
  .leftBorder {
    &:before {
      display: none;
    }
  }

  .navList {
    display: none;

    @include for-size(tablet-landscape-down) {
      display: block;
    }
  }

  .navItem {
    &:last-of-type {
      padding-right: 10px;
    }
  }

  .navLink {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .primaryButton {
    width: 100%;
  }
}

.mobileList {
  padding: 20px 0;
  position: absolute;
  top: 60px;
  width: 100%;
  left: 0;
}
.mobileButton {
  cursor: pointer;
}

.mobileMask {
  @include for-size(tablet-landscape-down) {
    display: block;
  }

  @include for-size(tablet-landscape-up) {
    display: none;
  }

  position: fixed;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $z-base;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}
