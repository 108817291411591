@import 'src/styles/variables';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  padding: 0 20px;
  min-height: 50px;
  max-width: $tablet-landscape-upper-boundary;

  @include for-size(tablet-landscape-down) {
    flex-wrap: wrap;
  }
}

.navLink {
  line-height: 32px;

  a {
    &:hover {
      color: $blue-100;
      text-decoration: underline;
    }
  }
}

.flexSpacer {
  flex-grow: 1;
}

.footerNav {
  margin-left: 40px;
  flex-basis: 150px;

  @include for-size(tablet-landscape-down) {
    margin-left: 0;
    flex-basis: 32%;
    flex-grow: 1;
    min-width: 150px;
    margin-bottom: 40px;
  }
}

.navTitle {
  color: $darkblue;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 13px;
}

.navItem {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sitemap {
  background-color: $white;
  padding: 50px 0;
}

.containerTop {
  align-items: flex-start;
}

.darkblue {
  background: $enterprise-gradient;

  .sitemap,
  .copyright {
    background: transparent;
  }

  .navTitle,
  .descriptionText {
    color: $white;
  }

  a.contactLink {
    color: $white;

    &:hover {
      color: $yellow-100;
      text-decoration: underline;
    }
  }

  .navLink,
  .link {
    a:hover {
      color: $yellow-100;
      text-decoration: underline;
    }

    a {
      text-decoration: none;
      color: $blue-5;
    }
  }

  .icon {
    color: $white;

    &:hover {
      color: $yellow-100;
    }
  }
}

.logo {
  flex-shrink: 0;
  margin-bottom: 20px;
}

.copyright {
  background-color: $white;
}

.contact {
  border-top: 1px solid $gray-20;
  justify-content: space-between;
}

.section {
  margin: 20px 0;
  display: flex;
  justify-content: center;

  @include for-size(tablet-portrait-down) {
    flex-grow: 1;
    margin: 5px 0;
  }
}

a.contactLink {
  color: $black;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  // border: 1px solid transparent;
  border-radius: 4px;
  // padding: 10px;
  transition: all 0.2s $cubic;

  &:hover {
    color: $blue;
    // border-color: $blue;
    text-decoration: underline;
  }

  @include for-size(tablet-portrait-down) {
    font-size: 14px;
  }
}

.linkIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;

  @include for-size(phone-only) {
    padding-left: 10px;
    height: 48px;
    width: 48px;
  }

  &:first-of-type {
    padding-left: 0;
  }
}

.icon {
  width: 32px;
  height: 32px;
  color: $black;
  transition: all 0.2s $cubic;

  &:hover {
    color: $blue;
  }
}

.description {
  width: 400px;
  padding-right: 50px;
  margin-right: auto;

  @include for-size(tablet-landscape-down) {
    width: 100%;
    margin-bottom: 40px;
    padding-right: 0;
    margin-right: 0;
  }
}

.descriptionText {
  color: $gray-60;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
}

.intercomIcon {
  // .icon {
  //   background: $blue;
  //   color: $blue;
  //   border-radius: 50%;
  //   padding: 2.5px;
  //   margin: -2.5px;

  //   g {
  //     fill: white;
  //   }

  //   &:hover {
  //     color: $darkblue;
  //     background: $darkblue;
  //   }
  // }
}
