@import 'src/styles/variables';

.white {
  color: $white;
}

.black {
  color: $black;
}

.blue {
  color: $blue;
}

.darkblue {
  color: $darkblue;
}

.darkgray {
  color: $dark-gray;
}

.logo {
  height: 24px;
  // width: 174.5px;
  width: 160px;
}

.hdLogo {
  // height: 50px;
  // width: 154px !important;
  // margin: 0 auto !important;
  width: 160px;
  height: 32px;
}
